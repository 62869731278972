@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}


.inline-radio
{
  display: inline;
  width: auto;
  height: 12px;
  vertical-align: middle;
  width: 12px;
  margin-right: 4px;
  color: #858585;
}

.inline-label
{
  display: inline;

}
/* Loader */

.loader-parent
{
  position: relative;
}
.loader-container
{
  position: absolute;
  left: 48%;
  top: 45%;
  width: 100%;
  height: 100%;
}
.loader-content
{
  position: relative;
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 12px solid #e3e3e3; /* Light grey */
  border-top: 12px solid #fc4c46; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.whatsapp-icon
{
  width: 18px;
  height: 18px;
}

.star
{
  color: rgb(227, 25, 25);
}
.flex
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body-container
{
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 100vh;
}

.suggestion-item {
  border-bottom: 1px solid #efefef;
  padding: 4px;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
  font-size: 0.85rem;
}

.suggestion-item-active {
  border-bottom: 1px solid #e3e3e3;
  background-color: #f3f5f7;
  padding: 4px;
  font-size: 0.85rem;
}
.autocomplete-dropdown-container {
  position: absolute;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  left: 0;
  z-index: 9;
  top: 100%;
  padding: 4px 4px;
}
.autocomplete-input {
  border: 1px solid #d3d3d3;
  background-color: #fff;
  position: relative;
  padding: 6px 12px;
  width: 100%;
  font-size: 0.9rem;
  border-radius: 4px;
}
.location-search-input {
  background-color: transparent;
}
* {
  font-family: "Inter";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fee-type-flex
{
  display: flex;
}

.fee-type-select
{
  font-size: .75rem!important;
  width: auto;
  padding-left: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  padding-right: 4px;
  font-weight: 400;
}

.fee-input
{
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}


/* toggle */

.toggle-container
{
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.toggle-content
{
  display: flex;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
}
.toggle-item
{
  display: flex;
  border: 1px solid transparent;
  justify-content: center;
  cursor: pointer;
}
.toggle-item p
{
  font-size: .8rem;
  padding: 6px 18px;
  font-weight: 600;
}
.toggle-item-active
{
  display: flex;
  background-color: #ff5b56;
  justify-content: center;
}

.toggle-item-active p
{
  color: #fff;
}

/* Utils */

.copy-btn
{
  display: inline-block;
  margin-left: 8px;
  font-size: .75rem;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  color: #fff;
  border: 0;
  background-color: rgb(41, 126, 255);
  transition: all .15s ease-in-out;
}
.copy-btn:active
{
  background-color: rgb(0, 78, 194);
}



/* Global styles */


.badge
{
  background-color: #f3f3f3;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  padding: 4px 12px;
  font-size: .7rem;
  letter-spacing: -.35px;
  font-weight: 600;
}


.completed-badge
{
  background-color: rgba(76, 165, 121,.25);
  border-radius: 16px;
  border: 1px solid #4ca579;
  padding: 3px 12px;
  color: #4ca579;
}


.not-completed-badge
{
  background-color: rgba(255, 152, 88,.15);
  border-radius: 16px;
  border: 1px solid #ff9858;
  padding: 3px 12px;
  color: #ff9858;
}


.status-badge
{
  background-color: #f3f3f3;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
  padding: 4px 12px;
}

.status-badge-active
{
  background-color: rgba(76, 165, 121,.25);
  border-radius: 16px;
  border: 1px solid #4ca579;
  padding: 3px 12px;
  color: #4ca579;
}

.status-dispute-badge
{
  background-color: rgba(255, 152, 88,.15);
  border-radius: 16px;
  border: 1px solid #ff9858;
  padding: 4px 12px;
  color: #ff9858;
}

label {
  font-size: 0.8rem;
  color: #757575;
  display: block;
  margin-bottom: 4px;
}

input {
  width: 100%;
  border: 0;
}

input[type='text']:disabled,select:disabled
{
  background-color: #f3f3f3;
}
.text-input,
select {
  border: 1px solid #d3d3d3;
  background-color: #fefefe;
  border-radius: 3px;
  padding: 6px 16px;
  font-size: 0.85rem;
  /* display: block; */
  outline: none;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.cta-input
{
  padding: 12px 16px;
}

input:active,
input:focus {
  outline: none;
}

.text-input:active,
.text-input:focus {
  border: 1px solid #ff5b56;
  outline: 0;
}

.multi-select {
  width: 100%;
}
.input-group {
  margin-bottom: 24px;
  display: block;
}

.checkbox-input-group
{
  display: flex;
}

.checkbox-input-group input
{
  display: inline-block;
  width: auto;
  margin-top: 4px;
  margin-right: 12px;
}
.input-group-divided {
  display: grid;
  grid-template-columns: 48% 48%;
  row-gap: 16px;
  justify-content: space-between;
  margin-bottom: 24px;
}
.input-group-divided-three {
  display: grid;
  align-content: flex-start;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  row-gap: 16px;
}
.input-group-divided-four {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  row-gap: 16px;
}

.input-group-divided-five {
  display: grid;
  grid-template-columns: 19.5% 19.5% 19.5% 19.5% 19.5%;
  justify-content: space-between;
  row-gap: 16px;
}

.split-detail-container {
  display: grid;
  grid-template-columns: 50% 50%;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


.submit-input-group {
  margin-top: 40px;
}

.xxl-text {
  font-size: 1.4rem;
  letter-spacing: -0.35px;
  line-height: 1.618;
}

.xl-text {
  font-size: 1.2rem;
  letter-spacing: -0.35px;
  line-height: 1.618;
}

.lg-text {
  font-size: 1.1rem;
  letter-spacing: -0.35px;
  line-height: 1.618;
}

.md-text {
  font-size: 1.05rem;
  letter-spacing: -0.25px;
  line-height: 1.618;
}

.m-text {
  font-size: 1.01rem;
  letter-spacing: -0.15px;
  line-height: 1.618;
}

.r-text {
  font-size: 0.975rem;
  line-height: 1.8;
}

.sm-text {
  font-size: 0.95rem;
  line-height: 1.8;
}

.xs-text {
  font-size: 0.9rem;
  line-height: 1.618;
}

.xxs-text {
  font-size: 0.85rem;
  letter-spacing: -0.15px;
  line-height: 1.618;
}

.xxxs-text {
  font-size: 0.75rem;
  letter-spacing: -0.15px;
  line-height: 1.618;
}

.xxxxs-text {
  font-size: 0.6rem;
  letter-spacing: -0.15px;
  line-height: 1.618;
}

.l-align {
  text-align: left;
}

.r-align {
  text-align: right;
}
.c-align {
  text-align: center;
}
.b-weight {
  font-weight: 600;
  letter-spacing: -0.15px;
}

.m-weight {
  font-weight: 500;
}

.r-weight {
  font-weight: 400;
}

.l-weight {
  font-weight: 300;
}

.q-op {
  color: #454545 !important;
}

.f-op {
  color: #151515 !important;
}

.h-op {
  color: #858585 !important;
}


.brand-text
{
  color: rgb(227, 25, 25)!important;
}
.bg-h-op {
  position: relative;
  opacity: 0.85 !important;
}
.bg-h-op::before {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f3f5f9;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  content: "";
}

.caps {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.sub-text {
  color: #ff5b56 !important;
}

.mb-xs {
  margin-bottom: 2px;
}

.mb-r {
  margin-bottom: 6px;
}

.mb-md {
  margin-bottom: 12px;
}

.mb-lg {
  margin-bottom: 20px;
}

.mb-xl {
  margin-bottom: 32px;
}

.mb-xxl {
  margin-bottom: 48px;
}


.ml-r {
  margin-left: 6px;
}

.r-line,
p {
  line-height: 1.4 !important;
  margin-bottom: 0;
}
/* Auth */

.auth-parent {
  background-color: #f3f5f7;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.auth-content {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  max-width: 480px;
  width: 100%;
  padding: 80px 48px;
}

.send-otp-btn {
  margin-top: 8px;
  font-weight: 500;
  border: 0;
  text-align: right;
  text-decoration: underline;
  font-size: 0.85rem;
  border-radius: 4px !important;
  margin-right: 0;
  color: #ff5b56;
  border: 0 solid #ff5b56;
  background-color: transparent;
}

.send-otp-btn:disabled
{
  color: #959595!important;
  cursor: not-allowed;
}
/* Nav */

.nav-bar {
  background-color: #fff;
  width: 100%;
}

.nav-bar-content {
  border-bottom: 1px solid #e3e3e3;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  padding: 0 24px;
  align-items: center;
}

.nav-menu-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo
{
  height: 36px;
  margin-right: 12px;
  border-radius: 3px;
  width: auto;
}
.nav-item {
  font-size: 0.95rem;
  font-weight: 500;
  color: #111;
  text-decoration: none;
  padding: 20px 24px;
}
.nav-item {
  border-bottom: 3px solid transparent;
}

.navbar-brand
{
  color: #ff5b56;
  font-weight: 800;
  margin-bottom: 0;
  line-height:1;
  padding-bottom: 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.auth-logo-container
{
  display: grid;
  justify-content: center;
}
.auth-logo-container p
{
  color: #ff5b56;
  font-weight: 800;
  margin-bottom: 0;
  text-align: center;
  line-height:1;
  padding-bottom: 0;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 16px;
}
.auth-logo
{
  height: 64px;
  margin-right: 12px;
  border-radius: 3px;
  margin: auto;
  text-align: center;
  width: auto; 
}

.nav-item:hover,.navbar-brand:hover {
  color: #ff5b56;
}
.nav-item-active {
  color: #ff5b56;
  border-bottom-color: #ff5b56;
}

.sub-nav {
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
}

.sub-nav-container {
  max-width: 1600px;
  display: flex;
  justify-content: center;
  padding: 0 40px;
}
.sub-nav-item {
  padding: 10px 24px;
  display: inline-block;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  color: #111;
  border-bottom: 2px solid transparent;
}

.sub-nav-item:hover {
  color: #ff5b56;
}

.sub-nav-item-active {
  color: #ff5b56;
  border-bottom-color: #ff5b56;
}
.main-container {
  padding: 24px 40px;
}

.btn {
  padding: 16px 20px;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
  color: #fff !important;
  background-color: #ff5b56 !important;
  border-radius: 4px;
}
.btn:disabled
{
  cursor: not-allowed;
  opacity:0.65
}
.xs-btn {
  padding: 8px 16px;
  font-size: 0.85rem;
  border: 0;
  color: #fff;
  font-weight: 600;
  transition: all .2s ease-in-out;
  text-decoration: none;
  background-color: #ff5b56;
  border-radius: 2px;
}
.xs-btn:hover {
  background-color: #d83a34;
}

input[type='submit']:disabled {
  padding: 8px 16px;
  font-size: 0.85rem;
  color: #fff;
  border: 1px solid #c3c3c3!important;
  background-color: #c3c3c3!important;
  border-radius: 4px;
}

.xxs-btn {
  font-size: 0.75rem;
  padding: 4px 8px;
  font-weight: 500;
  border: 1px solid #ff5b56;
  text-decoration: none;
  background-color: rgba(255, 91, 86, 0.1);
  color: #ff5b56;
  border-radius: 2px;
}

.compact-btn {
  background-color: #ff5b56 !important;
  border: 0;
  color: #fff !important;
  box-shadow: 0px 1px 2px 2px rgba(203, 199, 209, 0.25);
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  width: auto !important;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  width: auto;
  outline: none;
  cursor: pointer;
}

.compact-xs-btn {
  background-color: #ff5b56 !important;
  border: 1px solid #ff5b56;
  color: #fff !important;
  box-shadow: 0px 1px 2px 2px rgba(255, 91, 86, 0.2);
  text-align: center;
  padding: 6px 12px;
  width: auto !important;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 400;
  width: auto;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.compact-xs-btn:hover {
  background-color: #fc4c46 !important;
  box-shadow: none;
}

.compact-inverted-btn {
  color: #000;
  box-shadow: 0px 1.5px 8px 0.5px rgba(255, 91, 86, 0.2);
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  /* color: #fff; */
  border: 1px solid #e3e3e3;
  text-align: center;
  background-color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 400;
  width: auto;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.compact-inverted-btn:hover {
  box-shadow: none;
}

.compact-inverted-btn:active,
.compact-inverted-btn:focus {
  border: 1px solid #e3e3e3 !important;
}

.xs-compact-inverted-btn {
  color: #ff5b56;
  box-shadow: 0px 0.75px 0.5px 1px rgba(255, 91, 86,.2);
  border: 1px solid #d3d3d3;
  text-align: center;
  background-color: rgba(255, 91, 86,.1);
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  width: auto;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border: 1px solid #ff5b56;
}
.xs-compact-inverted-btn:hover {
  background-color: rgba(255, 91, 86,.2);
  box-shadow: 0px 0.75px 0.5px 1px rgba(255, 91, 86,.2);
}

/* Tooltip */

.tooltip-parent
{
  position: fixed!important;
}
.tutorTooltip
{

}
.tooltip {
  display: none;
  background-color: #fff!important;
  height: auto;
  min-height: 80px;
  position: fixed;
  z-index: 99999;
  /* box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.05); */
  border-radius: 6px;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 520px;
}


.tooltip-trigger-img
{
  height: 12px;
  width: 12px;
  cursor: pointer;
  margin-left: 4px;
}
.tooltip-trigger:hover .tooltip
{
  display: block!important;
}

.tooltip-right
{
  right: 0!important;
}

/* Modal */

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  border-left: 1px solid rgba(100, 100, 100, 0.15);
  box-shadow: -2px 1px 4px 1px rgba(100, 100, 100, 0.05);
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  padding: 24px 0;
  overflow-y: scroll;
}
.modal-header {
}

.modal-parent {
  display: block;
  background-color: #fff;
  height: auto;
  min-height: 520px;
  border-radius: 4px;
  /* overflow: hidden; */
  position: relative;
  width: 100%;
  max-width: 620px;
}

.modal-md {
  max-width: 520px;
  min-height: 320px;
}

.modal-xl {
  max-width: 780px;
  min-height: 320px;
}

.modal-xxl {
  max-width: 920px;
  min-height: 320px;
}

.modal-xxxl {
  max-width: 1280px;
  min-height: 320px;
}


.modal-inner-content {
  /* overflow-y: auto;ss */
  padding: 0;
  min-height: 180px;
  width: 100%;
  padding: 24px 40px;
}
.modal-header {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 12px 40px;
  z-index: 9;
  /* background-color: #f5f7f9; */
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  justify-content: space-between;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px 40px;
  padding-top: 14px;
  z-index: 9;
  border-top: 1px solid #efefef;
  width: 100%;
}
.modal-split-footer {
  justify-content: space-between;
}


.modal-right-footer
{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.payment-modal-footer {
  justify-content: space-between;
}

.modal-footer:nth-child(1) {
  margin-right: 24px;
}

.modal-content {
}

.modal-submit-group {
  display: flex;
  justify-content: flex-end;
  z-index: 99;
}

.modal-submit-group input {
  width: auto;
  padding: 12px 24px;
  font-size: 0.9em;
  font-weight: 400;
}

.modal-submit-group .modal-close {
  margin-right: 8px;
  cursor: pointer;
}


/* Side Modal */


/* Modal */

.side-modal {
  position: fixed;
  box-shadow: -2px 1px 4px 1px rgba(100, 100, 100, 0.05);
  top: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.side-modal-overlay
{
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;

}
.side-modal-header {
}

.side-modal-parent {
  display: block;
  overflow-y: scroll;
  border-radius: 6px;
  background-color: #fff;
  height: auto;
  height: 98vh;
  /* overflow: hidden; */
  position: fixed;
  right: 12px;
  top: 1%;
  width: 100%;
  max-width: 420px;
}

.side-modal-inner-content {
  /* overflow-y: auto;ss */
  padding: 0;
  min-height: 180px;
  width: 100%;
  padding: 24px 24px;
}
.side-modal-header {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 12px 40px;
  z-index: 9;
  /* background-color: #f5f7f9; */
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  justify-content: space-between;
}

.side-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 12px 40px;
  padding-top: 14px;
  z-index: 9;
  border-top: 1px solid #efefef;
  width: 100%;
}

.side-modal-footer:nth-child(1) {
  margin-right: 24px;
}

.side-modal-content {
}

.side-modal-submit-group {
  display: flex;
  justify-content: flex-end;
  z-index: 99;
}

.side-modal-submit-group input {
  width: auto;
  padding: 12px 24px;
  font-size: 0.9em;
  font-weight: 400;
}

.side-modal-submit-group .modal-close {
  margin-right: 8px;
  cursor: pointer;
}

/* Tag */

.tag {
  padding: 6px 8px;
  border-radius: 18px;
  margin: 4px 4px;
  display: inline-block;
  border: 1px solid #e3e3e3;
  background-color: #f3f5f7;
}

.tag-content {
  display: flex;
  align-self: center;
  align-items: center;
}
.tag-content p {
  font-size: .8rem;
  margin-bottom: 0;
}

.tag-selectable {
  padding: 6px 12px;
  border-radius: 18px;
  cursor: pointer;
  margin: 4px 4px;
  display: inline-block;
  border: 1px solid #e9e9e9;
  background-color: #fafafa;
}

.tag-selectable-active
{
  background-color: rgba(255, 91, 86,0.25);
  border: 1px solid #ff5b56;
}

.tag-selectable-content {
  display: flex;
  align-self: center;
  align-items: center;
}
.tag-selectable-content p {
  font-size: .8rem;
  margin-bottom: 0;
  color: #151515;
}

.tag-selectable-active .tag-selectable-content p {
  font-size: .8rem;
  margin-bottom: 0;
  color: #ff5b56;
}

.tag-close-btn
{
  height: 20px;
  background-color: rgba(255, 92, 87,.75);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  width: 20px;
  margin-left: 6px;
  cursor: pointer;
}
.tag-close-btn:hover
{
  background-color: rgba(255, 92, 87,.95);
}
.tag-close-btn p
{
  color: #fff;
  font-weight: 500;
}
/* List Content style */

.list-content {
  background-color: #fff;
  margin: 24px 24px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  max-width: 1480px;
  height: 100%;
}
.list-content-header {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid #e3e3e3;
  justify-content: space-between;
  align-items: center;
}

.split-main-container {
  display: grid;
  grid-template-columns: 35% 65%;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.split-main-container > div:nth-child(1)
{
  height: 100%;
}

.split-main-container > div:nth-child(2)
{
  height: 100%;
  overflow-y: scroll;
}

.split-contained-container {
  display: grid;
  grid-template-columns: 45% 45%;
  margin: auto;
  justify-content: space-around;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  max-width: 1280px;
  margin-top: 24px;
  min-height: 70vh;
}

.split-left-content {
  background-color: #fff;
}

.split-contained-container .split-left-content {
  border: 1px solid #e3e3e3;
}
.split-right-container {
  margin: 24px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* Pending Enquiry */

.primary-mobile-radio-container
{
  display: inline-block;
    width: 20px;
}
.subject-select-grid-item
{
  grid-column: 1/3;
}

.assigned-to-indicator
{
  height: 15px;
  display: inline-block;
  width: 15px;
  vertical-align: middle;
  /* margin: auto; */
  /* display: flex; */
  text-align: center;
  /* justify-content: center; */
  align-items: center;
  /* align-self: center; */
  /* background-color: #446aff; */
  border:1px solid #446aff;
  border-radius: 12px;
  /* letter-spacing: -.5px; */
  /* font-size: .5rem; */
  /* color: #fff; */
  /* text-transform: uppercase; */
  /* font-weight: 800; */
}
.assigned-to-indicator p
{
  letter-spacing: -.5px;
  font-size: .6rem;
  color: #446aff;
  height: 100%;
  padding-top: 0px;
  margin: auto;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 800;
}


.assigned-to-left
{
  margin-right: 4px;
}
.primary-badge
{
  background-color: #ff5b56;
  padding: 3px 8px;
  letter-spacing: .15px;
  font-size: .6rem;
  color: #fff;
  font-weight: 600;
  border-radius: 12px;
  text-transform: uppercase;
}

.premium-badge
{
  background-color: #446aff;
  padding: 3px 8px;
  letter-spacing: -.15px;
  font-size: .6rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 12px;
}

.rescheduled-badge
{
  background-color: rgba(255, 91, 86,0.1);
  border: 1px solid #ff5b56;
  padding: 3px 8px;
  letter-spacing: -.15px;
  font-size: .6rem;
  margin-left: 6px;
  color: #ff5b56!important;
  font-weight: 600;
  border-radius: 3px;
}

.enquiry-part-two
{
  margin-top: 20px;
  padding-top: 20px;
  border-top: 2px dotted #b3b3b3;
}
.enquiry-note-item
{
  grid-column: 1/3;
}

.enquiry-table-parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  background-color: #fff;
  border-right: 1px solid #e3e3e3;
  width: 100%;
}

.search-input {
  background-color: #f3f5f7;
  border-radius: 3px;
  padding: 6px 12px;
  border: 1px solid #e3e3e3;
  color: #151515;
  font-size: 0.85rem;
}

.enquiry-table-header {
  padding: 12px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}

.pending-item {
  padding: 16px 40px;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
}

.primary-enquiry-list-item
{
  border-left: 3px solid #446aff;
}

.pending-content {
  display: flex;
  justify-content: space-between;
}

.pending-info-container {
  padding: 24px;
}

.pending-info-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

.pending-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
}

.pending-info-btn-container {
  display: flex;
}
.tertiary-btn {
  color: rgb(3, 59, 145) !important;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  text-align: center;
  padding: 4px 12px;
  text-decoration: underline;
  border-radius: 4px;
  margin-right: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  width: auto;
  outline: none;
  cursor: pointer;
}

.xs-neutral-btn {
  background-color: #fafafa !important;
  border: 1px solid #033b91;
  color: #033b91 !important;
  box-shadow: 0px 1px 2px 2px rgba(203, 199, 209, 0.25);
  text-align: center;
  padding: 6px 12px;
  text-decoration: none;
  width: auto !important;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  width: auto;
  outline: none;
  margin-right: 6px;
  cursor: pointer;
}

.xs-delete-btn {
  background-color: #ff5b56 !important;
  border: 1px solid #ff5b56;
  color: #fff !important;
  box-shadow: 0px 1px 2px 2px rgba(203, 199, 209, 0.25);
  text-align: center;
  padding: 6px 12px;
  text-decoration: none;
  width: auto !important;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  width: auto;
  outline: none;
  margin-right: 6px;
  cursor: pointer;
}

.xs-approve-btn {
  background-color: #4ca579 !important;
  border: 1px solid #4ca579;
  color: #fff !important;
  box-shadow: 0px 1px 2px 2px rgba(203, 199, 209, 0.25);
  text-align: center;
  padding: 6px 12px;
  text-decoration: none;
  width: auto !important;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  width: auto;
  outline: none;
  cursor: pointer;
}

.pending-info-grid {
  display: grid;
  grid-template-columns: 60% 40%;
  row-gap: 24px;
}

.pending-info-grid div {
  padding-right: 24px;
}

.pending-inner-content {
  padding: 24px 0;
  height: 100%;
}

/* Mapping Section */


.role-based-search-container
{
  display: flex;
}
.role-city-select
{
  background-color: #f3f5f7;
  border: 1px solid #e3e3e3;
  width: 100%;
  margin-right: 0;
  border-radius: 0;
  font-size: .8rem;
  border-right: 0;
  padding: 0 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  max-width: 100px;
}

.role-city-search
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mapping-split-container {
  display: grid;
  grid-template-columns: 30% 70%;
}

.mapping-list-container {
  margin: 24px;
  height: auto!important;
  display: flex;
  align-content: stretch;
}

.mapping-list-content {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  width: 100%;
  overflow-y: hidden;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

.mapping-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
}


.mapping-multi-filter input[type='checkbox']
{
  width: auto;

  /* overflow: hidden; */
}

.mapping-filter__control
{
  /* height: 32px; */
}

.tutor-search-box {
  width: 100%;
  margin-right: 12px;
  background-color: #e3e3e3;
}

.mapping-filter-flex
{
  display: flex;
}

.mapping-filter-flex select
{
  height: 100%;
}

.mapping-filter-flex div:first-child,.mapping-filter-flex div:nth-child(2)
{
  margin-right: 8px;
}


.multi-select
{
  width: 240px;
}
.rmsc .dropdown-container
{
  border: 0;
}


.rmsc .dropdown-heading
{
  border: 1px solid #e3e3e3;
  background-color: #f3f5f7!important;
  border-radius: 3px;
  padding: 0px 8px;
  font-size: 0.85rem;
  color: #151515;
}



/* Demo Section */

.demo-main-container {
  display: block;
  max-width: 1480px;
  margin: 24px;
}

.demo-list-container {
}

.demo-list-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 24px 0;
}

.demo-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
}

.demo-table-container {
  width: 100%;
}

/* Settings */

.info-container {
}

.info-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #e3e3e3;
}

.info-content {
  padding: 16px 20px;
}

/* Category */

.sub-category-container {
  margin-bottom: 16px;
  border-bottom: 1px solid #efefef;
  padding: 16px;
}
.sub-category-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.sub-category-header h5 {
  margin-right: 12px;
  align-self: center;
  margin-bottom: 0;
}

/* Clients */

.client-container {
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: stretch;
  /* justify-content: center; */
}

.client-search-container {
  /* text-align: center; */
  display: flex;
  width: 360px;
  background-color: #fff;
  padding: 16px 24px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  border-top-color: transparent;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  flex-direction: row;
  /* align-items: center; */
}

.client-search-box {
  width: 100%;
  margin-right: 12px;
  font-size: .85rem;
}

.client-info-container {
  margin-top: 16px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  min-height: 320px;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
}

.client-info-grid {
  display: grid;
  grid-template-columns: 35% 65%;
  height: 100%;
}

.client-info-left-content {
  border-right: 1px solid #e3e3e3;
  padding: 24px 40px;
}


.client-info-header
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 24px;
}

.client-info-btn-container
{
  display: flex;
}


.client-info-content
{
  padding-top: 24px;
}
/* Tutor */


.tutor-notes-grid-item
{
  grid-column: 2/4;
}

.tutor-languages-grid-item
{
  grid-column: 1/4;
}

.tutor-split-content
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

.tutor-header-container
{
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 12px 24px;
}

.tutor-list-item
{
  padding: 16px 0;
}

.tutor-list-container
{
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.tutor-right-container
{
  padding: 0 24px;
  height: 580px;
  overflow-y: scroll;
}
.tutor-category-container
{
  /* margin-bottom: 16px; */
  padding: 0 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e3e3e3;
  /* background-color: #fafafa; */
}

.tutor-category-container-header-flex
{
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  /* border-bottom: 1px solid #f3f3f3; */
  padding: 12px 0;
  /* background-color: #fafafa; */
}

.category-badge
{
  background-color: #efefef;
  font-weight: 600;
  vertical-align: middle;
  border-radius: 4px;
  padding: 4px 12px;
}

.tutor-syllabus-container
{
  padding: 16px 0;
  /* padding-left: 24px; */
  /* background-color: #f3f3f3; */
  /* border-left: 1px solid #d3d3d3; */
}


.tutor-category-syllabus-accordion
{
  display: none;
}


.tutor-category-syllabus-accordion-active
{
  display: block;
}

.accordion-icon
{
  height: 14px;
  width: 14px;
}
.tutor-info-container
{
  border-radius: 3px;
  padding: 24px;
  overflow-y: scroll;
  height: 100%;
  display: flex;
    flex-direction: column;
}

.tutor-info-content
{
  background-color: #fff;
  border: 1px solid #e3e3e3;
  /* height: 100%; */
  padding: 24px;
  overflow-y: scroll;
}

.tutor-info-header
{
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #e3e3e3;
}

.tutor-info-grid
{
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 12px;
  height: 100%;
}

.tutor-info-content-grid
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 24px 0;
  align-content: flex-start;
}


.tutor-info-header-btn-container
{
    display: flex;
}

.tutor-info-right-container
{
  padding-left: 24px;
  border-left: 1px solid #e3e3e3;
}

/* Enquiry Detail */

.enquiry-detail-right-content
{
  border-left: 1px solid #e3e3e3;
  padding-left: 24px;
}

.demo-detail-list-item
{
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 0px 12px;
  padding-bottom: 12px;
  background-color: #f5f7f9;
  border-radius: 6px;
  margin-bottom: 24px;
  /* border-left: 2px solid #ff5b56; */
}
.demo-detail-list-item .header-flex
{
  /* border-bottom: 1px solid #e3e3e3; */
  padding: 6px 12px;
  padding-bottom: 6px;
}

.demo-detail-list-item .bottom-flex
{
  padding-top: 6px;
  padding-left: 12px;
}
.demo-info-sub-item
{
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 6px;
  padding: 10px 12px;
}

.enquiry-header-toggle-container
{
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.enquiry-header-toggle-content
{
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
}
.enquiry-header-toggle-item
{
  display: flex;
  border: 1px solid transparent;
  justify-content: center;
  cursor: pointer;
}
.enquiry-header-toggle-item p
{
  font-size: .85rem;
  padding: 6px 18px;
  font-weight: 500;
}
.enquiry-header-toggle-item-active
{
  display: flex;
  background-color: #ff5b56;
  justify-content: center;
}

.enquiry-header-toggle-item-active p
{
  color: #fff;
}

.time-select-popup
{
  border: 1px solid #e3e3e3;
  background-color: #fff;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.005)!important;
}

.time-select-popup
{
  border: 0px solid #e3e3e3;
  border-radius: 4px;
  z-index: 9999;
  box-shadow: none!important;  
}

.rc-time-picker-input
{
  border: 1px solid #d3d3d3;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0)!important;
  background-color: #fefefe;
  border-radius: 3px;
  padding: 6px 16px;
  font-size: 0.85rem;
  /* display: block; */
  outline: none;
  width: 100%!important;
  transition: all 0.2s ease-in-out;
}

.rc-time-picker-panel-inner
{
  box-shadow: none!important;
}

.time-input
{
  display: inline-block;
  width: 100%!important;
}

.time-input input
{
  padding: 8px 16px;
  font-size: .85rem;
  height: 35px;
  font-weight: 400;
  color: #151515;
}

.rc-time-picker-panel
{
  background-color: #fff;
}


/* Detailed Enquiry */
.enquiry-detailed-container
{
  margin: 24px;
}

.enquiry-detailed-content
{
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  display: grid;
  grid-template-columns: 1fr 1fr;
}


/* Tutor Doc Section  */

.tutor-doc-grid
{
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;

justify-content: space-between;}
/* File Container */

.file-container
{
  max-width: 100%;
  padding: 6px;
  background-color: #f5f5f7;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
}

.file-container-content
{
  padding: 24px 6px;
}

.file-container-input
{
  display: none;
}

.view-btn
{
  margin-left: 6px;
}



/* Secondary Tuition */

.secondary-tutor-container
{
  overflow-y: scroll;
}

/* Update Tuition */


.payment-modal-top-tgrid-summary
{
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 12px 24px;
  justify-content: space-between;
  background-color: #fafafa;
  border-bottom: 1px solid #e3e3e3;
}
.payment-modal-top-summary
{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  padding: 12px 24px;
  justify-content: space-between;
  background-color: #fafafa;
  border-bottom: 1px solid #e3e3e3;
}

.payment-modal-split-container
{
  display: grid;
  grid-template-columns: 38% 58%;
  justify-content: space-between;
}

.payment-item
{
  padding: 12px 16px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 4px;
}


.payment-item-header-flex
{
  display: flex;
  justify-content: space-between;
}


.add-payment-container
{
  background-color: #f7f8f9;
  border-radius: 6px;
  border: 1px solid #efefef;
  margin-top: 24px;
  padding: 12px 16px;
}


.payment-link-span
{
  grid-column: 1/3;
}

.payment-checkbox-container
{
  margin-top: 16px;
  grid-column: 1/3;
}


/* Info Popup */

.info-popup-header
{
  border-bottom: 1px solid #e3e3e3;
  padding: 12px 24px;
  display: flex;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;

}
.info-popup-grid
{
  display: grid;
  padding: 24px;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
}


/* Tutor Info Popup */

.tutor-popup-expertise-container
{
  padding: 0 4px;
  border-top: 1px solid #e3e3e3;
  padding-top: 24px;
}



/* Analytics */


/* Client Analytics Page */

.client-analytics-container
{
  display: block;
  margin: 24px;
  background-color: #fff;
  border: 1px solid #e3e3e3;
}

.client-analytics-top-content
{
  padding: 24px;
  border-bottom: 1px solid #e3e3e3;
}

.client-analytics-header
{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #e3e3e3;
}

.client-analytics-content
{
  padding: 24px 0;
}

.client-analytics-enquiry-list
{
  height: 100%;
}