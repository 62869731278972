.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  background-color: #f3f7f9 !important;
}
.content {
  padding: 30px 40px;
}
.css-19kzrtu {
  padding-bottom: 0 !important;
}

.ant-slider-with-marks {
  margin-bottom: 44px;
}
.ant-slider-handle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid purple;
  /* margin-top: 0.8px; */
  margin-left: -1px;
  z-index: -1;
  border-radius: 0%;
}
.ant-slider-dot-active {
  display: none;
}
.ant-slider-track {
  background-color: #5b61ff;
  z-index: 999;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
.ant-radio-button-wrapper > .ant-radio-button {
  background-color: #5b61ff !important;
  /* color:white !important; */
}
.ant-radio-button-wrapper-checked:not {
  color: white;
}
.ant-radio-button-wrapper {
  color: grey;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  background-color: #5b61ff !important;
}
.headermain {
  /* max-width: 1280px; */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ant-modal-body {
  padding-top: 0 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
